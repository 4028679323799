import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./HeroImage.module.scss"

type HeroImageProps = {
  image: Queries.DatoHeroImageFragment | null
  mobileImage?: Queries.DatoHeroMobileImageFragment | null
}

const HeroImage = (props: HeroImageProps) => {
  // console.debug("Rendering HeroImage", props)
  const { image, mobileImage } = props

  if (!image) {
    return null
  }

  const objectPosition = image.focalPoint ? `${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%` : "50% 50%"

  return (
    <section className={`full-bleed ${mobileImage ? styles.hasMobileImg : ""}`}>
      <div className={styles.desktopImg}>
        <GatsbyImage
          alt={image.alt || ""}
          image={image.gatsbyImageData}
          imgStyle={{ objectPosition }}
          loading="eager"
        />
      </div>
      {mobileImage && (
        <div className={styles.mobileImg}>
          <GatsbyImage alt={image.alt || ""} image={mobileImage.gatsbyImageData} loading="eager" />
        </div>
      )}
    </section>
  )
}

export default HeroImage

export const query = graphql`
  fragment DatoHeroImage on DatoCmsFileField {
    #fluid(imgixParams: { fit: "crop", w: "2000", h: "500" }, maxWidth: 2000) {
    #  ...GatsbyDatoCmsFluid
    #}
    alt
    gatsbyImageData(layout: FULL_WIDTH, width: 2000, height: 500)
    focalPoint {
      x
      y
    }
  }
  fragment DatoHeroMobileImage on DatoCmsFileField {
    alt
    gatsbyImageData(layout: CONSTRAINED, width: 480)
    focalPoint {
      x
      y
    }
  }
`
